import React from "react";
import t from "prop-types";
import LoginComponent from "./LoginComponent";

const LoginClient = siteConfig => <LoginComponent siteConfig={siteConfig} />;

LoginClient.propTypes = {
  siteConfig: t.object.isRequired
};

export default LoginClient;

require('/var/lib/jenkins/workspace/pf-yts-ui_Release_0/src/pages/login/login.less');