import React, { Fragment } from "react";
import t from "prop-types";
import Header from "common/header/StdReactHeader";
import Footer from "common/footer/StdReactFooter";
import UILoginWidget from "ui-login-widget";

const LoginComponent = ({
  siteConfig
}) => {
  const {
    submarket,
    loginWidgetTheme,
    cprTxtArray
  } = siteConfig;

  const siteInfo = {
    realm: loginWidgetTheme,
    pageType: "login",
    homePageURL: "/educator",
    siteLogoClassName: "icon-logo icon-logo-yts-logo"
  };

  return (
    <Fragment>
      <Header siteInfo={siteInfo} />
      <main
        className='pf-login-content main-content text-center'
        role='main'
      >
        <UILoginWidget
          redirectURL={"login/success"}
          realm={submarket}
          loginWidgetTheme={loginWidgetTheme}
          loginButtonText='Sign In'
          height="400"
        />
      </main>
      <Footer
        cprTxtArray={cprTxtArray}
      />
    </Fragment>
  );
};

LoginComponent.propTypes = {
  siteConfig: t.object.isRequired
};

export default LoginComponent;

require('/var/lib/jenkins/workspace/pf-yts-ui_Release_0/src/pages/login/login.less');