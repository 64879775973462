import React, { Fragment } from "react";
import { hydrate } from "react-dom";
import LoginClient from "./LoginClient";
import ChatWidget from "../../shared_modules/five9-chat-widget";
import postRobot from "post-robot";
import registry from "dynamic-module-registry";

const siteConfig = registry.get("siteConfig");
const chatFive9Context = registry.get("chatFive9Context");

const handleLoginSuccess = (event) => {
  window.location.href = "login/success";
  return {};
};

const handleLoginError = () => {
  return {};
};

const getTheme = () => {
  let theme = "lc-yts";
  const host = window.location.host;

  if (host.indexOf("localhost") === -1) {
    if (host.indexOf("k12") > -1 || host.indexOf("yourtuitionsolution") > -1) {
      theme = "lc-k12";
    }
  }

  return theme;
};

const handleForgotPassword = () => {
  const theme = getTheme();
  window.location.href = `/auth/resetPassword/entry?theme=${theme}`;
  return {};
};

const renderCompleted = () => {
  const messageConfig = {
    window: window,
    domain: window.document.baseURI
  };
  document.addEventListener("DOMContentLoaded", function () {
    if (chatFive9Context?.isFiveChatEnabled) return;
    require("livechat");
    return;
  });

  postRobot.on("loginSuccess", messageConfig, handleLoginSuccess);
  postRobot.on("loginError", messageConfig, handleLoginError);
  postRobot.on("forgotPasswordClick", messageConfig, handleForgotPassword);
};

hydrate(
  <Fragment>
    <LoginClient {...siteConfig} />
    {chatFive9Context?.isFiveChatEnabled && (
      <ChatWidget chatFive9Context={chatFive9Context} />
    )}
  </Fragment>,
  document.getElementById("pf-login-widget-wrapper"),
  renderCompleted
);

require('/var/lib/jenkins/workspace/pf-yts-ui_Release_0/src/pages/login/login.less');